import classNames from 'classnames'
import React from 'react'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import type { Component } from 'constants/types'
import styles from './Utility.module.scss'
import { ReactComponent as LogoSvg } from './images/logo.svg'

type Props = {
  children: React.ReactNode
  title?: string
} & Component

export default function Utility({ children, className, title }: Props) {
  return (
    <Layout header={false} footer={false} className={classNames('Utility', className)}>
      <Head title={title} />
      <View>
        <Row size="xlarge">
          <LogoSvg aria-label="Bold" className={styles.logo} />
        </Row>
        {title && (
          <Row size="medium">
            <Text align="center" element="h1" size="xlarge" weight="semibold">
              {title}
            </Text>
          </Row>
        )}
        {children}
      </View>
    </Layout>
  )
}
